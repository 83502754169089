import { Link } from "react-router-dom";
import viberImg from "../../../assets/viber.svg";
import "./viber-button.scss";

const ViberButton = () => {
  return (
    <Link to={"https://www.viber.com/"} target="_blank">
      <button
        className="btn btn__social"
        style={{ gap: "20px", justifyContent: "flex-start" }}
      >
        <img style={{ height: "38px" }} src={viberImg} alt="Viber Icon" />
        Viber
      </button>
    </Link>
  );
};

export default ViberButton;
