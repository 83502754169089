import { Link } from "react-router-dom";
import logoImg from "../../assets/logo.png";
import "./footer.scss";
const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__top">
            <Link className="footer__logo" to={"/"}>
              <img className="logo__img" src={logoImg} alt="logo" />
            </Link>
            <ul className="menu__list">
              <li className="menu__list-item">
                <a
                  className="menu__list-link hover-effect"
                  href="/#service"
                  title="services"
                >
                  Послуги
                </a>
              </li>
              <li className="menu__list-item">
                <Link
                  className="menu__list-link hover-effect"
                  to="/contacts"
                  title="contacts"
                >
                  Контакти
                </Link>
              </li>
              <li className="menu__list-item">
                <a
                  className="menu__list-link hover-effect"
                  href="/#about"
                  title="about"
                >
                  Про нас
                </a>
              </li>
              <li className="menu__list-item">
                <Link
                  className="menu__list-link hover-effect"
                  to="mailto:support@dvocorp.com"
                  title="Send e-mail"
                >
                  support@dvocorp.com
                </Link>
              </li>
            </ul>
            <ul className="social__list">
              <li className="social__list-item">
                <Link
                  className="social__list-link hover-effect-2"
                  to="https://www.instagram.com/"
                  title="Instagram"
                  target="_blank"
                >
                  <svg
                    width="36.000000"
                    height="36.000000"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <desc>Created with Pixso.</desc>
                    <defs>
                      <clipPath id="clip13_124">
                        <rect
                          id="insta"
                          rx="18.000000"
                          width="36.000000"
                          height="36.000000"
                          fill="white"
                          fillOpacity="0"
                        />
                      </clipPath>
                    </defs>
                    <rect
                      id="insta"
                      rx="18.000000"
                      width="36.000000"
                      height="36.000000"
                      fill="#FFFFFF"
                      fillOpacity="0.500000"
                    />
                    <g clipPath="url(#clip13_124)">
                      <path
                        id=""
                        d="M18.02 12.84C15.16 12.84 12.88 15.16 12.88 17.97C12.88 20.83 15.16 23.11 18.02 23.11C20.83 23.11 23.15 20.83 23.15 17.97C23.15 15.16 20.83 12.84 18.02 12.84ZM18.02 21.32C16.19 21.32 14.67 19.85 14.67 17.97C14.67 16.14 16.14 14.67 18.02 14.67C19.85 14.67 21.32 16.14 21.32 17.97C21.32 19.85 19.85 21.32 18.02 21.32ZM24.54 12.66C24.54 11.99 24 11.46 23.33 11.46C22.66 11.46 22.12 11.99 22.12 12.66C22.12 13.33 22.66 13.87 23.33 13.87C24 13.87 24.54 13.33 24.54 12.66ZM27.93 13.87C27.84 12.26 27.48 10.83 26.32 9.67C25.16 8.51 23.73 8.15 22.12 8.06C20.47 7.97 15.52 7.97 13.87 8.06C12.26 8.15 10.87 8.51 9.67 9.67C8.51 10.83 8.15 12.26 8.06 13.87C7.97 15.52 7.97 20.47 8.06 22.12C8.15 23.73 8.51 25.12 9.67 26.32C10.87 27.48 12.26 27.84 13.87 27.93C15.52 28.02 20.47 28.02 22.12 27.93C23.73 27.84 25.16 27.48 26.32 26.32C27.48 25.12 27.84 23.73 27.93 22.12C28.02 20.47 28.02 15.52 27.93 13.87ZM25.79 23.87C25.47 24.76 24.76 25.43 23.91 25.79C22.57 26.32 19.45 26.19 18.02 26.19C16.54 26.19 13.42 26.32 12.12 25.79C11.23 25.43 10.56 24.76 10.2 23.87C9.67 22.57 9.8 19.45 9.8 17.97C9.8 16.54 9.67 13.42 10.2 12.08C10.56 11.23 11.23 10.56 12.12 10.21C13.42 9.67 16.54 9.8 18.02 9.8C19.45 9.8 22.57 9.67 23.91 10.21C24.76 10.52 25.43 11.23 25.79 12.08C26.32 13.42 26.19 16.54 26.19 17.97C26.19 19.45 26.32 22.57 25.79 23.87Z"
                        fill="#FFFFFF"
                        fillOpacity="1.000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </Link>
              </li>
              <li className="social__list-item">
                <Link
                  className="social__list-link hover-effect-2"
                  to="https://www.facebook.com/"
                  title="Facebook"
                  target="_blank"
                >
                  <svg
                    width="36.000000"
                    height="36.000000"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <desc>Created with Pixso.</desc>
                    <defs>
                      <clipPath id="clip13_126">
                        <rect
                          id="facebook"
                          rx="18.000000"
                          width="36.000000"
                          height="36.000000"
                          fill="white"
                          fillOpacity="0"
                        />
                      </clipPath>
                    </defs>
                    <rect
                      id="facebook"
                      rx="18.000000"
                      width="36.000000"
                      height="36.000000"
                      fill="#FFFFFF"
                      fillOpacity="0.500000"
                    />
                    <g clipPath="url(#clip13_126)">
                      <path
                        id=""
                        d="M25.85 8L10.14 8C8.93 8 8 8.98 8 10.14L8 25.85C8 27.06 8.93 28 10.14 28L16.25 28L16.25 21.21L13.44 21.21L13.44 18L16.25 18L16.25 15.58C16.25 12.82 17.91 11.25 20.41 11.25C21.66 11.25 22.91 11.48 22.91 11.48L22.91 14.2L21.52 14.2C20.14 14.2 19.69 15.05 19.69 15.94L19.69 18L22.77 18L22.28 21.21L19.69 21.21L19.69 28L25.85 28C27.01 28 28 27.06 28 25.85L28 10.14C28 8.98 27.01 8 25.85 8Z"
                        fill="#FFFFFF"
                        fillOpacity="1.000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </Link>
              </li>
              <li className="social__list-item">
                <Link
                  className="social__list-link hover-effect-2"
                  to="https://www.linkedin.com/"
                  title="LinkedIn"
                  target="_blank"
                >
                  <svg
                    width="36.000000"
                    height="36.000000"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <desc>Created with Pixso.</desc>
                    <defs>
                      <clipPath id="clip13_128">
                        <rect
                          id="linked in"
                          rx="18.000000"
                          width="36.000000"
                          height="36.000000"
                          fill="white"
                          fillOpacity="0"
                        />
                      </clipPath>
                    </defs>
                    <rect
                      id="linked in"
                      rx="18.000000"
                      width="36.000000"
                      height="36.000000"
                      fill="#FFFFFF"
                      fillOpacity="0.500000"
                    />
                    <g clipPath="url(#clip13_128)">
                      <path
                        id=""
                        d="M12.47 28L12.47 14.62L8.31 14.62L8.31 28L12.47 28ZM10.37 12.83C11.71 12.83 12.78 11.71 12.78 10.37C12.78 9.07 11.71 8 10.37 8C9.07 8 8 9.07 8 10.37C8 11.71 9.07 12.83 10.37 12.83ZM28.04 28L28.04 20.66C28.04 17.08 27.23 14.3 23.03 14.3C21.02 14.3 19.67 15.42 19.09 16.45L19.05 16.45L19.05 14.62L15.06 14.62L15.06 28L19.23 28L19.23 21.37C19.23 19.63 19.54 17.97 21.69 17.97C23.83 17.97 23.88 19.94 23.88 21.51L23.88 28L28.04 28Z"
                        fill="#FFFFFF"
                        fillOpacity="1.000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
          <span className="footer__line"></span>
          <div className="footer__bottom">Всі права захищено © 2024</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
