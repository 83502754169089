import { Swiper, SwiperSlide } from "swiper/react";
import gearImg from "../../assets/gear.png";
import dbImg from "../../assets/db.png";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { EffectCoverflow, Pagination, Mousewheel } from "swiper/modules";

import "./slider.scss";

const Slider = (props) => {
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      loop={true}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: false,
      }}
      pagination={true}
      modules={[Pagination, EffectCoverflow, Mousewheel]}
      className="mySwiper"
    >
      <div class="swiper-wrapper">
        <SwiperSlide>
          <a href="!#" className="swiper-slide__item-wrapper">
            <div className="swiper-slide__content">
              <div className="swiper-slide__title">
                Плати менше отримуй більше
              </div>
              <div className="swiper-slide__text">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                commodo dolor fermentum dignissim et pellentesque egestas
                mauris, faucibus. Tellus nisi amet non at phasellus faucibus
                senectus in”
              </div>
              <button className="btn swiper-slide__btn">
                Переглянути акцію
              </button>
            </div>
            <div className="swiper-slide__image">
              <img src={gearImg} alt="" />
            </div>
            <span className="swiper-slide__line"></span>
          </a>
          <div className="offer__good">Вигідна пропозиція</div>
        </SwiperSlide>
        <SwiperSlide>
          <a href="!#" className="swiper-slide__item-wrapper">
            <div className="swiper-slide__content">
              <div className="swiper-slide__title">
                Плати менше отримуй більше
              </div>
              <div className="swiper-slide__text">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                commodo dolor fermentum dignissim et pellentesque egestas
                mauris, faucibus. Tellus nisi amet non at phasellus faucibus
                senectus in”
              </div>
              <button className="btn swiper-slide__btn">
                Переглянути акцію
              </button>
            </div>
            <div className="swiper-slide__image">
              <img src={dbImg} alt="" />
            </div>
            <span className="swiper-slide__line"></span>
          </a>
          <div className="promotion__good offer__good">Акція</div>
        </SwiperSlide>
        <SwiperSlide>
          <a href="!#" className="swiper-slide__item-wrapper">
            <div className="swiper-slide__content">
              <div className="swiper-slide__title">
                Плати менше отримуй більше
              </div>
              <div className="swiper-slide__text">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                commodo dolor fermentum dignissim et pellentesque egestas
                mauris, faucibus. Tellus nisi amet non at phasellus faucibus
                senectus in”
              </div>
              <button className="btn swiper-slide__btn">
                Переглянути акцію
              </button>
            </div>
            <div className="swiper-slide__image">
              <img src={gearImg} alt="" />
            </div>
            <span className="swiper-slide__line"></span>
          </a>
          <div className="offer__good">Вигідна пропозиція</div>
        </SwiperSlide>
        <SwiperSlide>
          <a href="!#" className="swiper-slide__item-wrapper">
            <div className="swiper-slide__content">
              <div className="swiper-slide__title">
                Плати менше отримуй більше
              </div>
              <div className="swiper-slide__text">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                commodo dolor fermentum dignissim et pellentesque egestas
                mauris, faucibus. Tellus nisi amet non at phasellus faucibus
                senectus in”
              </div>
              <button className="btn swiper-slide__btn">
                Переглянути акцію
              </button>
            </div>
            <div className="swiper-slide__image">
              <img src={dbImg} alt="" />
            </div>
            <span className="swiper-slide__line"></span>
          </a>
          <div className="promotion__good offer__good">Акція</div>
        </SwiperSlide>
        <SwiperSlide>
          <a href="!#" className="swiper-slide__item-wrapper">
            <div className="swiper-slide__content">
              <div className="swiper-slide__title">
                Плати менше отримуй більше
              </div>
              <div className="swiper-slide__text">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                commodo dolor fermentum dignissim et pellentesque egestas
                mauris, faucibus. Tellus nisi amet non at phasellus faucibus
                senectus in”
              </div>
              <button className="btn swiper-slide__btn">
                Переглянути акцію
              </button>
            </div>
            <div className="swiper-slide__image">
              <img src={gearImg} alt="" />
            </div>
            <span className="swiper-slide__line"></span>
          </a>
          <div className="offer__good">Вигідна пропозиція</div>
        </SwiperSlide>
        <SwiperSlide>
          <a href="!#" className="swiper-slide__item-wrapper">
            <div className="swiper-slide__content">
              <div className="swiper-slide__title">
                Плати менше отримуй більше
              </div>
              <div className="swiper-slide__text">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                commodo dolor fermentum dignissim et pellentesque egestas
                mauris, faucibus. Tellus nisi amet non at phasellus faucibus
                senectus in”
              </div>
              <button className="btn swiper-slide__btn">
                Переглянути акцію
              </button>
            </div>
            <div className="swiper-slide__image">
              <img src={dbImg} alt="" />
            </div>
            <span className="swiper-slide__line"></span>
          </a>
          <div className="promotion__good offer__good">Акція</div>
        </SwiperSlide>
      </div>
    </Swiper>
  );
};

export default Slider;
