import ArrowUp from "../../assets/arrow-up.png";
import "./scroll-arrow.scss";
import React, { useState, useEffect } from "react";

const ScrollArrow = () => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 2) {
        setShowArrow(true);
      } else {
        setShowArrow(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    scrollToTop();
  };

  return (
    <div
      className={`scroll-button ${showArrow ? "show" : ""}`}
      onClick={scrollToTop}
      onContextMenu={handleContextMenu}
    >
      <img src={ArrowUp} alt="arrow" />
    </div>
  );
};

export default ScrollArrow;
