import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/app/App";
import ErrorBoudary from "./components/errorBoudary/ErrorBoudary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageDev from "./components/page-dev/page-dev";
import ContactsPage from "./pages/contacts-page/contacts-page";
import { MetricsProvider, MetricsLogger } from "@cabify/prom-react";
import { customMetrics } from "./metrics/cutom-metrics/custom-metrics";
import "./index.scss";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <React.StrictMode>
        <App />
      </React.StrictMode>
    ),
    errorElement: <ErrorBoudary />,
  },
  {
    path: "contacts",
    element: <ContactsPage />,
  },
  {
    path: "login",
    element: <PageDev />,
  },
]);

createRoot(document.getElementById("root")).render(
  <>
    <MetricsProvider
      appName="dvocorp-app"
      owner="DVO-team"
      metricsAggregatorUrl="https://gateway.cd-network.org/metrics/"
      customMetrics={customMetrics}
    >
      <MetricsLogger />
      <RouterProvider router={router} />
    </MetricsProvider>
  </>
);
