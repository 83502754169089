import { Link } from "react-router-dom";
import mailImg from "../../../assets/mail.png";
import "./mail-button.scss";

const MailButton = () => {
  return (
    <Link to="mailto:support@dvocorp.com" target="_blank">
      <button
        className="btn btn__social"
        style={{ gap: "20px", justifyContent: "flex-start" }}
      >
        <img style={{ height: "38px" }} src={mailImg} alt="E-mail Icon" />
        Надіслати E-mail
      </button>
    </Link>
  );
};

export default MailButton;
