import { Link } from "react-router-dom";
import botImg from "../../../assets/bot.png";
import "./air-btn.scss";

const AirButton = (props) => {
  return (
    <Link className="user-nav__link-fixed" to="https://t.me/dvo_support_bot">
      <button className="user-nav__link-btn user-nav__link-btn-fixed">
        <p className="user-nav__link-text">Написати в підтримку</p>
        <img className="user-nav__link-img text" src={botImg} alt="bot icon" />
      </button>
    </Link>
  );
};

export default AirButton;
