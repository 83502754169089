export const customMetrics = [
  {
    type: "counter",
    name: "home_page_visits",
    description: "Number of visits to the home page",
  },
  {
    type: "counter",
    name: "request_errors",
    description: "Number of request errors",
  },
];
