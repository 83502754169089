import axios from "axios";

export const token =
  "UCYw4k47Tc1DEbMtihivB7ynw6SU6hnMymJL5e6VYkH2sHBeqNdg7J5oiMDPUuSc";

const httpService = () => {
  const instance = axios.create({
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const get = async (url) => {
    try {
      const response = await instance.get(url);
      return response.data;
    } catch (error) {
      console.error("Error in GEEEET request:", error);
      throw error;
    }
  };

  const post = async (url, data) => {
    try {
      const response = await instance.post(url, data);
      return response;
    } catch (error) {
      if (error.response) {
        console.error(`Request URL: ${url}`);
        console.error(`Request Method: POST`);
        console.error(
          `Status Code: ${error.response.status} ${error.response.statusText}`
        );
        console.error(`Response Data:`, error.response.data);
        console.error(`Headers:`, error.response.headers);
        return error.response;
      } else if (error.request) {
        console.error(`Request URL: ${url}`);
        console.error(`Request Method: POST`);
        console.error(`No response received`);
        console.error(`Request Data:`, error.request);
      } else {
        console.error("Error in setting up the request:", error.message);
      }
      throw error;
    }
  };

  return { get, post };
};

export { httpService };
