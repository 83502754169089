import React, { useState } from "react";
import "./menu.scss";
import { Link } from "react-router-dom";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="menu">
      <button
        className={`menu__btn ${isMenuOpen ? "menu__btn-active" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
      </button>
      <ul className={`menu__list ${isMenuOpen ? "menu__list__active" : ""}`}>
        <li className="menu__list-item">
          <a
            className="menu__list-link hover-effect"
            href="/#service"
            title="services"
            onClick={handleLinkClick}
          >
            Послуги
          </a>
        </li>
        <li className="menu__list-item">
          <Link
            className="menu__list-link hover-effect"
            to="/contacts"
            title="contact"
            onClick={handleLinkClick}
          >
            Контакти
          </Link>
        </li>
        <li className="menu__list-item">
          <a
            className="menu__list-link hover-effect"
            href="/#about"
            title="about"
            onClick={handleLinkClick}
          >
            Про нас
          </a>
        </li>
        <li className="menu__list-item">
          <Link
            className="user-nav__link hover-effect"
            to={"/login"}
            onClick={handleLinkClick}
          >
            Вхід
            <svg
              width="27.000000"
              height="29.000000"
              viewBox="0 0 27 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <desc>Created with Pixso.</desc>
              <defs />
              <path
                id="path"
                d="M14.33 25.83C13.44 25.83 13 26.26 13 27.12C13 27.98 13.44 28.41 14.33 28.41L25 28.41C25.88 28.41 26.33 27.98 26.33 27.12L26.33 1.29C26.33 0.43 25.88 0 25 0L14.33 0C13.44 0 13 0.43 13 1.29C13 2.15 13.44 2.58 14.33 2.58L23.66 2.58L23.66 25.83L14.33 25.83Z"
                fill="#FFFFFF"
                fillOpacity="1.000000"
                fillRule="nonzero"
              />
              <path
                id="path"
                d="M0 14.47C0 15.33 0.44 15.76 1.33 15.76L16.78 15.76L13.72 18.72C13.45 18.97 13.32 19.28 13.31 19.64C13.31 20 13.44 20.31 13.7 20.57C13.97 20.82 14.28 20.95 14.66 20.94C15.03 20.94 15.35 20.81 15.6 20.55L20.94 15.38C21.57 14.77 21.57 14.16 20.94 13.56L15.6 8.39C15.35 8.13 15.03 8 14.66 8C14.28 7.99 13.97 8.12 13.7 8.37C13.44 8.63 13.31 8.94 13.31 9.3C13.32 9.66 13.45 9.96 13.72 10.22L16.78 13.18L1.33 13.18C0.44 13.18 0 13.61 0 14.47Z"
                fill="#FFFFFF"
                fillOpacity="1.000000"
                fillRule="nonzero"
              />
            </svg>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
