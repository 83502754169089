import React from "react";
import "./popup.scss";

const Popup = ({ isOpenPopup, setIsPopupOpen, text }) => {
  const handleClose = () => {
    setIsPopupOpen(false);
  };
  return (
    <>
      <div className={`popup__bg  ${isOpenPopup && "active"}`}>
        <div className="popup__box">
          <p className="popup__box-notification">{text}</p>
          <p className="close-popup"></p>
          <div className="close-popup" onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
