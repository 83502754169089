import Footer from "../footer/footer";
import Header from "../header/header";
import ScrollArrow from "../scroll-arrow/scroll-arrow";
import "./layout.scss";

const Layout = ({ children }) => {
  return (
    <div className="layout ">
      <Header />
      <main className="layout__inner">{children}</main>
      <Footer />
      <ScrollArrow />
    </div>
  );
};

export default Layout;
