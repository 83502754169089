import { useEffect, useMemo, useState } from "react";
import About from "../about/about";
import AirButton from "../buttons/air-btn/air-btn";
import Contact, { ContentForm } from "../contact/contact";
import Footer from "../footer/footer";
import Header from "../header/header";
import Proffer from "../proffer/proffer";
import ScrollArrow from "../scroll-arrow/scroll-arrow";
import Servc from "../serv/serv";
import ScrollToAnchor from "../scroll-to-anchor/scroll-to-anchor";
import { useMetrics } from "@cabify/prom-react";
import { usePerformanceMark, Stage } from "@cabify/prom-react";
import { getUserIPAddress } from "../../services/ipService/ip";

import "./App.scss";

function App() {
  const { observe } = useMetrics();
  const [ip, setIp] = useState("");

  usePerformanceMark(Stage.Complete, "home", { ip });
  const fetchIPAddress = async () => {
    const ip = await getUserIPAddress();
    setIp(ip);
  };

  useEffect(() => {
    fetchIPAddress();
  }, []);

  useEffect(() => {
    if (ip) {
      observe("home_page_visits", { ip }, 1);
    }
  }, [ip, observe]);

  return (
    <>
      <ScrollToAnchor />
      <div className="wrapper">
        <Header />
      </div>
      <main>
        <Servc />
        <Proffer />
        <About />
        <Contact>
          <ContentForm />
        </Contact>
      </main>
      <Footer />
      <AirButton />
      <ScrollArrow />
    </>
  );
}

export default App;
