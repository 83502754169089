import Slider from "../slider/slider";

import "./proffer.scss";
const Proffer = () => {
  return (
    <section className="proffer">
      <div className="container">
        <h2 className="proffer__title">Акції та пропозиції</h2>
      </div>
      <div className="container">
        <div className="proffer__slider">
          <Slider />
        </div>
      </div>
    </section>
  );
};

export default Proffer;
