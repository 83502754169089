import { Link } from "react-router-dom";
import telegramImg from "../../../assets/telegram.svg";
import botImg from "../../../assets/bot-another.png";
import "./telegram-button.scss";

const TelegramButton = ({ link, type }) => {
  return (
    <Link to={link} target="_blank">
      <button
        className="btn btn__social"
        style={{ gap: "20px", justifyContent: "flex-start" }}
      >
        <img
          style={{ height: "38px" }}
          src={type === "support" ? botImg : telegramImg}
          alt="Telegram Icon"
        />
        {type === "support" ? "Support Telegram" : "Telegram"}
      </button>
    </Link>
  );
};

export default TelegramButton;
