import { useRouteError } from "react-router-dom";
import NotFound from "../not-found/not-found";

const ErrorBoundary = () => {
  let error = useRouteError;
  console.error(error);
  return <NotFound />;
};

export default ErrorBoundary;
