import abotImg from "../../assets/about.png";
import "./about.scss";

const About = (props) => {
  return (
    <section className="about" id="about">
      <div className="container">
        <div className="about__wrapper">
          <h2 className="about__title">Про нас</h2>
          <div className="about__content">
            <div className="about__text">
              Наша досвідчена команда системних адміністраторів і спеціалістів
              DevOps із досвідом понад 5 років готова забезпечити безперебійну
              роботу ваших програм на будь-якому сервері, нашому чи вашому.
            </div>
            <div className="about__image">
              <img className="about__img" src={abotImg} alt="devops" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
