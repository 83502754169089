import develop from "../../assets/develop.svg";
import { Link } from "react-router-dom";
import "./page-dev.scss";

const PageDev = () => {
  return (
    <div className="page-dev">
      <div className="page-dev__content">
        <img src={develop} alt="develop" />
        <p className="page-dev__subtitle">Сторінка в розробці</p>
        <Link className="btn page-dev__link" to={"/"}>
          Повернутися на гловну
        </Link>
      </div>
    </div>
  );
};

export default PageDev;
