import "./not-found.scss";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <div className="not-found">
      <div className="not-found__content">
        <h2 className="not-found__title">404</h2>
        <p className="not-found__subtitle">Вибачте, сторінку не знайдено</p>
        <Link className="btn not-found__link" to={"/"}>
          Повернутися на гловну
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
