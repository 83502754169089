import "./header.scss";
import Menu from "../menu/menu";
import Logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const { pathname } = useLocation();
  const isLayout = pathname === "/contacts";
  return (
    <header className="header">
      <div className="container">
        <div className={isLayout ? "" : "header__wrapper"}>
          <div className="header__inner">
            <Link className="header__logo" to={"/"}>
              <img className="logo__img" src={Logo} alt="logo" />
            </Link>
            <div className="header__menu">
              <Menu />
            </div>
          </div>
          {isLayout ? (
            ""
          ) : (
            <div className="header__content">
              <h1 className="header__title">Вас вітає - DVO</h1>
              <h2 className="header__subtitle">ми — ваша підтримка</h2>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
