import ViberButton from "../../components/buttons/viber-button/viber-button";
import Contact, { ContentForm } from "../../components/contact/contact";
import Layout from "../../components/layout/layout";
import TelegramButton from "../../components/buttons/telegram-button/telegram-button";
import MailButton from "../../components/buttons/mail-button/mail-button";
import "./contacts-page.scss";

const ContactsPage = () => {
  return (
    <Layout>
      <div className="contacts-page">
        <Contact>
          <div className="contacts-page__buttons">
            <ViberButton />
            <TelegramButton link={"https://t.me/dvo_support_bot"} />
            <TelegramButton
              link={"https://t.me/dvo_support_bot"}
              type={"support"}
            />
            <MailButton />
          </div>
          <ContentForm />
        </Contact>
      </div>
    </Layout>
  );
};

export default ContactsPage;
