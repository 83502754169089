import mailImg from "../../assets/email.svg";
import Popup from "../popup/popup";
import { useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { useMetrics } from "@cabify/prom-react";
import { httpService } from "../../services/httpService/http";
import { useLocation } from "react-router-dom";
import "./contact.scss";

const Contact = ({ children }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const [popup, setPopup] = useState(false);
  const [textError, setTextError] = useState("");
  const { pathname } = useLocation();
  const { observe } = useMetrics();
  const { post } = httpService();
  const isLayout = pathname === "/contacts";

  const onSubmit = useCallback(
    async (data) => {
      try {
        const res = await post(
          "https://api.dvocorp.com/v1/site/send_form",
          data
        );
        if (res.status === 200) {
          setTextError("Дякуємо! З Вами зв'яжуться в найближній час 😊");
          setPopup(true);
          reset();
        }
      } catch (AxiosError) {
        observe(
          "request_errors",
          {
            endpoint: "https://api.dvocorp.com/v1/site/send_form",
            errorMessage: AxiosError,
            method: "POST",
          },
          1
        );
        setTextError("Щось пішло не так...😔");
        setPopup(true);
      }
    },
    [observe, reset, setTextError, setPopup]
  );

  return (
    <>
      <Popup setIsPopupOpen={setPopup} isOpenPopup={popup} text={textError} />
      <section
        className={`contact ${isLayout && "contacts-page"}`}
        id="contact"
      >
        <div className="container">
          <div className="contact__wrapper">
            <div className={`contact__content ${isLayout && "contacts"}`}>
              {children}
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="contact__form">
              <input
                className={`contact__form-input ${
                  errors.name && "contact__form-input--error"
                }`}
                placeholder="Ім'я"
                {...register("name", { required: true, minLength: 2 })}
              />
              <input
                className={`contact__form-input ${
                  errors.telegram && "contact__form-input--error"
                }`}
                placeholder="Телеграм @nickname"
                {...register("telegram", { required: true, minLength: 5 })}
              />
              <label>
                <input
                  className={`contact__form-input ${
                    errors.email && "contact__form-input--error"
                  }`}
                  placeholder="Електронна пошта"
                  {...register("email", {
                    required: "E-mail поле обов'язкове",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Некоректний формат електронної пошти",
                    },
                  })}
                />
                {errors.email && (
                  <p className="contact__form-lable">{errors.email.message}</p>
                )}
              </label>
              <label>
                <textarea
                  className={`contact__form-input contact__form-textarea ${
                    errors.email && "contact__form-input--error"
                  }`}
                  placeholder="Повідомлення"
                  {...register("message", {
                    required: "Це поле обов'язкове",
                    minLength: {
                      value: 10,
                      message:
                        "Повідомлення повинно містити не менше 10 символів",
                    },
                    maxLength: {
                      value: 1000,
                      message:
                        "Повідомлення не може перевищувати 1000 символів",
                    },
                  })}
                />
                {errors.message && (
                  <p className="contact__form-lable">
                    {errors.message.message}
                  </p>
                )}
              </label>

              <button className="contact__form-btn btn" type="submit">
                Зв'язатися з нами
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;

export const ContentForm = () => {
  return (
    <>
      <div className="contact__text">
        <img className="contact__img" src={mailImg} alt="email icon" />
        Дозвольте нам вирішити технічні моменти, щоб ви могли зосередитися на
        успіху свого бізнесу.
      </div>
    </>
  );
};
