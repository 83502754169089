import Hosting from "../../assets/hosting.svg";
import Devops from "../../assets/devops.svg";
import "./servc.scss";

const Servc = () => {
  return (
    <section className="service" id="service">
      <div className="container">
        <h2 className="service__title">Послуги</h2>
        <div className="service__wrapper">
          <div className="service__item">
            <div className="service__content">
              <span className="service__line"></span>
              <h3 className="service__item-title">Оренда хостингу</h3>
              <h5 className="service__subtitle">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id dui
                pharetra elementum sit id sagittis non donec egestas.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Id dui pharetra
                elementum sit id sagittis non donec egestas.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Id dui pharetra elementum
                sit id sagittis non donec egestas.
              </h5>
              <a className="service__link" href="!#">
                <button className="service__btn btn">Орендувати хостинг</button>
              </a>
            </div>
            <div className="service__image">
              <img
                className="service__img"
                src={Hosting}
                alt="hosting imageF"
              />
            </div>
          </div>
        </div>
        <div className="service__wrapper">
          <div className="service__item last">
            <div className="service__image">
              <img className="service__img" src={Devops} alt="devops" />
            </div>
            <div className="service__content">
              <span className="service__line"></span>
              <h2 className="service__item-title">DevOps підтрика</h2>
              <p className="service__subtitle">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id dui
                pharetra elementum sit id sagittis non donec egestas.Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Id dui pharetra
                elementum sit id sagittis non donec egestas.Lorem ipsum dolor
                sit amet, consectetur adipiscing elit. Id dui pharetra elementum
                sit id sagittis non donec egestas.
              </p>
              <a className="service__link" href="!#">
                <button className="service__btn btn">Орендувати хостинг</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Servc;
